<template>
  <YoTable
    :api-endpoint="apiEndpoint"
    title-tbl="Tujuan"
    sub-title-tbl="Data Muat"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :bottom-label=" this.$t('KeteranganTable') + '~'+ this.$t('Form Land Vehicle')+'~'"
    bottom-icon="GitMergeIcon"
    placeholder-search="Route"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/CompTableT1T2.vue'

export default {
  components: {
    YoTable,
  },
  data() {
    return {
      apiEndpoint: 'muat',
      fields: [
        {
          searchModel: 'group.name',
          search: {
            type: 'text',
          },
          minWidth: '130px',
          key: 'group.name',
          label: 'GROUP',
          placeholder: '',
          sortable: true,
          input: { type: 'input-noarea', disabled: true },
        },
        {
          searchModel: 'no_document',
          search: {
            type: 'text',
          },
          key: 'no_document',
          label: 'No Dokumen',
          placeholder: 'No Dokumen',
          sortable: true,
          input: { type: 'input-noarea' },
        },
        {
          searchModel: 'pemilik_brg',
          search: {
            type: 'text',
          },
          key: 'pemilik_brg',
          label: 'Pemilik Barang',
          placeholder: 'Pemilik Barang',
          sortable: true,
          input: { type: 'input-noarea' },
        },
        {
          searchModel: 'tujuan',
          search: {
            type: 'text',
          },
          key: 'tujuan',
          label: 'Tujuan',
          sortable: true,
          placeholder: 'Tujuan',
          input: { type: 'input-noarea' },
        },
        {
          searchModel: 'jml_muatan',
          search: {
            type: 'number',
          },
          key: 'jml_muatan',
          label: 'Jumlah Muatan',
          sortable: true,
          placeholder: 'Jumlah Muatan',
          input: { type: 'number' },
        },
        {
          key: 'satuan',
          label: 'Satuan',
          sortable: true,
          input: {
            type: 'select',
            options: [
              {
                label: 'M3',
                value: 'M3',
              },
              {
                label: 'TON',
                value: 'TON',
              },
              {
                label: 'LTR',
                value: 'LTR',
              },
            ],
            reduce({ value }) {
              return value
            },
          },
          searchModel: 'satuan',
          search: {
            type: 'text',
          },
        },
        {
          key: 'user.full_name',
          label: 'Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true },
          searchModel: 'user.full_name',
          search: {
            type: 'text',
          },
        },
        {
          searchModel: 'user.phone',
          search: {
            type: 'number',
          },
          key: 'user.phone',
          label: 'Phone of Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true },
        },
        {
          searchModel: 'created_at',
          search: {
            type: 'date',
          },
          key: 'created_at',
          label: 'Created AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true,
          },
        },
        {
          searchModel: 'updated_at',
          search: {
            type: 'date',
          },
          key: 'updated_at',
          label: 'Updated AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true,
          },
        },
      ],
    }
  },
  computed: {
    newDataTemplate() {
      return {
        no_document: '',
        pemilik_brg: '',
        tujuan: '',
        jml_muatan: '',
        satuan: 'TON',
      }
    },
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.muats
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC',
        },
      }
    },
  },
}
</script>
